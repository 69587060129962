import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    ConfirmationModal,
    Panel,
    Button,
    Icon,
    IconTypes,
    useSmUp,
} from '@pexip/components';

import type {
    BreakoutAskingForHelpStatus,
    BreakoutParticipants,
} from '../../types';
import {BreakoutRoomVariant} from '../../types';
import {PanelHeader} from '../PanelHeader/PanelHeader.view';
import {BreakoutRoom} from '../BreakoutRoom/BreakoutRoom.view';
import {TestId} from '../../../test/testIds';
import {BreakoutRoomsAskingForHelp} from '../BreakoutRoomsAskingForHelp/BreakoutRoomsAskingForHelp.view';

import styles from './BreakoutPreviewPanel.module.scss';

export const BreakoutPreviewPanel: React.FC<{
    breakoutsAskingForHelp: BreakoutAskingForHelpStatus[];
    currentParticipants: BreakoutParticipants;
    showCloseRoomsButton: boolean;
    closeRoomsText: string;
    editRoomsText: string;
    breakoutRoomsNames: Record<string, string>;
    myIdentity?: string;
    amIControlOnlyParticipant: boolean;
    headerText?: string;
    onJoinRoom: (roomId: string) => void;
    onJoinRoomViaHelp: (roomId: string) => void;
    onCloseRoom: (roomId: string) => void;
    onCloseRooms: () => void;
    onClosePanel: () => void;
    onEditRooms: () => void;
}> = ({
    breakoutsAskingForHelp,
    currentParticipants,
    showCloseRoomsButton,
    closeRoomsText,
    editRoomsText,
    breakoutRoomsNames,
    myIdentity,
    amIControlOnlyParticipant,
    headerText,
    onJoinRoom,
    onJoinRoomViaHelp,
    onCloseRoom,
    onCloseRooms,
    onClosePanel,
    onEditRooms,
}) => {
    const {t} = useTranslation();
    const [isCloseRoomsModalOpen, setIsCloseRoomsModalOpen] = useState(false);
    const isSmUp = useSmUp();
    return (
        <>
            <Panel
                isRounded={isSmUp}
                padding="none"
                footerPadding="small"
                headerContent={
                    <PanelHeader
                        title={headerText}
                        onCloseClick={onClosePanel}
                    />
                }
                footerContent={
                    showCloseRoomsButton ? (
                        <Button
                            onClick={() => setIsCloseRoomsModalOpen(true)}
                            modifier="fullWidth"
                            variant="danger"
                            data-testid={TestId.CloseBreakoutRooms}
                        >
                            {closeRoomsText}
                        </Button>
                    ) : undefined
                }
                scrollElementClassname="pt-2 pr-3 pb-3 pl-3"
            >
                <Button
                    className={styles.editButton}
                    modifier="fullWidth"
                    enhancerStart={
                        <Icon size="compact" source={IconTypes.IconEdit} />
                    }
                    onClick={onEditRooms}
                    variant="tertiary"
                    data-testid={TestId.ButtonBreakoutRoomEdit}
                >
                    {editRoomsText}
                </Button>
                <BreakoutRoomsAskingForHelp
                    breakoutsAskingForHelp={breakoutsAskingForHelp}
                    onJoinRoom={onJoinRoomViaHelp}
                />
                {Array.from(currentParticipants.entries()).map(
                    ([roomId, participants]) => {
                        return (
                            <BreakoutRoom
                                key={roomId}
                                name={breakoutRoomsNames[roomId] ?? roomId}
                                id={roomId}
                                participants={participants}
                                myIdentity={myIdentity}
                                amIControlOnlyParticipant={
                                    amIControlOnlyParticipant
                                }
                                variant={BreakoutRoomVariant.Preview}
                                onJoinRoom={onJoinRoom}
                                onCloseRoom={onCloseRoom}
                            />
                        );
                    },
                )}
            </Panel>
            <ConfirmationModal
                cancelButtonText={t('common.cancel', 'Cancel')}
                cancelButtonTestid={TestId.CloseBreakoutRoomsModalCancel}
                confirmButtonText={t('common.yes', 'Yes')}
                confirmButtonTestid={TestId.CloseBreakoutRoomsModalConfirm}
                title={t(
                    'breakouts.close-all-rooms-modal.title',
                    'Close all rooms?',
                )}
                uniqueTitle={t(
                    'breakouts.close-all-rooms-modal.title',
                    'Close all rooms?',
                )}
                description={t(
                    'breakouts.close-all-rooms-modal.description',
                    'All participants will be moved to the main room.',
                )}
                isOpen={isCloseRoomsModalOpen}
                onConfirm={() => {
                    setIsCloseRoomsModalOpen(false);
                    onCloseRooms();
                }}
                onCancel={() => setIsCloseRoomsModalOpen(false)}
            />
        </>
    );
};
