import React from 'react';
import {useTranslation} from 'react-i18next';

import type {AriaMenuItem, GroupProps} from '@pexip/components';
import {IconTypes, AriaMenu, Button, Icon} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const BreakoutRoomsConfigurationMenu: React.FC<
    {
        onAddRoomButtonClick?: () => void;
        onShuffledButtonClick?: () => void;
        onSettingsButtonClick?: () => void;
        isShuffling?: boolean;
    } & GroupProps
> = ({
    onAddRoomButtonClick,
    onShuffledButtonClick,
    onSettingsButtonClick,
    isShuffling = false,
}) => {
    const {t} = useTranslation();
    const [isContentVisible, setContentVisible] = React.useState(false);

    const configurationItems: AriaMenuItem[] = [
        Boolean(onAddRoomButtonClick) && {
            key: 'addRoom',
            label: t('breakouts.add-room-button-text', 'Add room'),
            icon: <Icon source={IconTypes.IconAdd} size="compact" />,
            onItemClick: onAddRoomButtonClick,
            closeMenuOnItemClick: false,
            testId: TestId.ButtonBreakoutRoomAddRoom,
        },
        Boolean(onShuffledButtonClick) && {
            key: 'shuffle',
            label: isShuffling
                ? t('breakouts.shuffling-button-text', 'Shuffling...')
                : t('breakouts.shuffle-button-text', 'Shuffle'),
            icon: <Icon source={IconTypes.IconShuffle} size="compact" />,
            onItemClick: onShuffledButtonClick,
            testId: TestId.ButtonBreakoutRoomShuffle,
            closeMenuOnItemClick: false,
        },
        Boolean(onSettingsButtonClick) && {
            key: 'settings',
            label: t('breakouts.settings-button-text', 'Settings'),
            icon: <Icon source={IconTypes.IconSettings} size="compact" />,
            onItemClick: onSettingsButtonClick,
            closeMenuOnItemClick: false,
            testId: TestId.ButtonBreakoutRoomSettings,
        },
    ].filter(Boolean) as AriaMenuItem[];

    return (
        <AriaMenu
            onOpenChange={setContentVisible}
            items={configurationItems}
            button={
                <Button
                    isActive={isContentVisible}
                    size="compact"
                    modifier="square"
                    variant="translucent"
                    aria-label={t(
                        'breakouts.more-configuration-menu-aria-label',
                        'See more breakout rooms configuration options',
                    )}
                    data-testid={TestId.ButtonBreakoutRoomsConfiguration}
                >
                    <Icon source={IconTypes.IconMoreVertical} />
                </Button>
            }
            popoverPlacement="bottom left"
        />
    );
};

export type BreakoutRoomsConfigurationProps = React.ComponentProps<
    typeof BreakoutRoomsConfigurationMenu
>;
