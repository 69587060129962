import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {MenuItem} from '@pexip/components';
import {Button, Icon, IconTypes, Tooltip} from '@pexip/components';

import {ParticipantRowMoreActions} from '../ParticipantRowMoreActions/ParticipantRowMoreActions.view';
import type {InMeetingParticipant} from '../../types';
import {SpeakingIndicator} from '../SpeakingIndicator/SpeakingIndicator.view';
import {TestId} from '../../../test/testIds';

import type {RowActionState} from './ParticipantRowActions.module';
import {
    getShouldShowHostActions,
    getShouldShowIndicators,
} from './ParticipantRowActions.module';

import styles from './ParticipantRowActions.module.scss';

export const ParticipantRowActions: React.FC<
    Omit<RowActionState, 'isMuted' | 'isCameraMuted' | 'isPresenting'> & {
        isTouchDevice: boolean;
        isDirectMedia?: boolean;
        showParticipantAuthenticatedState: boolean;
        displayName: string;
        handleRemoveUserRequest: (
            participant: InMeetingParticipant,
            displayName: string,
        ) => void;
        handleTransfer?: (identity: string) => void;
        handleDTMF?: (identity: string) => void;
        handleFecc?: (identity: string) => void;
        isCurrentPeer: boolean;
        isHost: boolean;
        participant: InMeetingParticipant;
        onMouseLeave: () => void;
        additionalMenuContent: MenuItem[];
    }
> = ({
    isTouchDevice,
    isDirectMedia,
    showParticipantAuthenticatedState,
    displayName,
    handleRemoveUserRequest,
    handleTransfer,
    handleDTMF,
    handleFecc,
    hasChildCalls,
    isCurrentPeer,
    canPerformHostActions,
    isHost,
    isHover,
    isSpeaking,
    participant,
    onMouseLeave,
    additionalMenuContent,
}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {isMuted, isClientMuted, isCameraMuted, isPresenting, isSpotlight} =
        participant;

    const isInteracted = isHover || isOpen;

    const shouldShowIndicators = getShouldShowIndicators({
        canPerformHostActions,
        isInteracted,
    });
    const shouldShowHostActions = getShouldShowHostActions({
        canPerformHostActions,
        isCurrentPeer,
        isHost,
        isInteracted,
    });
    const shouldShowHostActionsForCurrentPeer = getShouldShowHostActions({
        canPerformHostActions,
        isCurrentPeer: false,
        isHost,
        isInteracted,
    });

    const shouldShowActionOrIndicator =
        shouldShowIndicators || shouldShowHostActions;

    const hasAdditionalActions =
        participant.spotlight ??
        participant.setRole ??
        participant.lowerHand ??
        handleTransfer ??
        handleDTMF ??
        handleFecc;
    const shouldShowAdditionalActions =
        shouldShowHostActionsForCurrentPeer &&
        hasAdditionalActions &&
        (participant.canSpotlight ||
            participant.canTransfer ||
            participant.canDisconnect);

    const menuButton = (
        <Button
            aria-label="Open menu"
            role="button"
            className={styles.indicator}
            isActive={isOpen}
            variant="secondary"
            modifier="square"
            size="compact"
            onClick={() => setIsOpen(!isOpen)}
            data-testid={TestId.ParticipantMenuButton}
        >
            <Icon
                size="small"
                className={styles.actionButtonIcon}
                source={IconTypes.IconMoreHorizontal}
            />
        </Button>
    );

    return (
        <div className={styles.moreMenuAndIndicatorsContainer}>
            {shouldShowAdditionalActions && (
                <ParticipantRowMoreActions
                    isOpen={isOpen}
                    isTouchDevice={isTouchDevice}
                    showParticipantAuthenticatedState={
                        showParticipantAuthenticatedState
                    }
                    isMuted={isMuted}
                    displayName={displayName}
                    handleTransfer={handleTransfer}
                    handleDTMF={handleDTMF}
                    handleFecc={handleFecc}
                    handleRemoveUserRequest={handleRemoveUserRequest}
                    shouldShowHostActions={shouldShowHostActions}
                    shouldShowHostActionsForCurrentPeer={
                        shouldShowHostActionsForCurrentPeer
                    }
                    isDirectMedia={isDirectMedia}
                    isCurrentPeer={isCurrentPeer}
                    participant={participant}
                    isSpotlight={isSpotlight}
                    additionalMenuContent={additionalMenuContent}
                    button={menuButton}
                    onOpenChange={state => {
                        setIsOpen(state);
                        if (!state) {
                            onMouseLeave();
                        }
                    }}
                />
            )}
            {isPresenting && shouldShowActionOrIndicator && (
                <Tooltip
                    text={t('media.presentation.presenting', 'Presenting')}
                    position="top"
                >
                    <Icon
                        data-testid={TestId.IconPresenting}
                        source={IconTypes.IconPresentationOn}
                        size="compact"
                    />
                </Tooltip>
            )}
            {isCameraMuted && (
                <Tooltip
                    text={t('media.camera-turned-off', 'Camera turned off')}
                    position="top"
                >
                    <Icon
                        data-testid={TestId.IconCameraMuted}
                        source={IconTypes.IconVideoOff}
                        size="compact"
                    />
                </Tooltip>
            )}
            {(isMuted || isClientMuted) && !hasChildCalls && (
                <Tooltip
                    text={
                        isMuted
                            ? t('media.muted-by-a-host', 'Muted by a host')
                            : t('media.muted', 'Muted')
                    }
                    position="top"
                >
                    <Icon
                        data-testid={
                            isMuted ? TestId.IconMutedByAHost : TestId.IconMuted
                        }
                        source={
                            isMuted
                                ? IconTypes.IconMicrophoneMutedByHost
                                : IconTypes.IconMicrophoneOff
                        }
                        size="compact"
                    />
                </Tooltip>
            )}
            {isSpeaking && !isMuted && shouldShowIndicators && (
                <SpeakingIndicator data-testid={TestId.SpeakingIndicator} />
            )}
        </div>
    );
};

export type ParticipantRowActionsProps = React.ComponentProps<
    typeof ParticipantRowActions
>;
