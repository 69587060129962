import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {VideoHandle} from '@pexip/components';
import {
    VideoWrapper,
    Spinner,
    Video,
    IconTypes,
    Icon,
    Button,
    CenterLayout,
    FontVariant,
    FullSizeWindow,
    Text,
    Audio,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {StreamStatus} from '../../types';
import {isMobileDevice} from '../../utils';
import {logger} from '../../logger';

import styles from './MeetingVideo.module.scss';

const getWrapperTestId = (isPresentation: boolean, isPip: boolean) => {
    if (isPresentation) {
        return isPip ? TestId.PresentationPip : TestId.PresentationFull;
    }
    return isPip ? TestId.MeetingVideoPip : TestId.MeetingVideoFull;
};

export type MeetingVideoRef = Omit<VideoHandle, 'resume'>;

export const MeetingVideo = React.forwardRef<
    MeetingVideoRef,
    {
        mediaStream?: MediaStream;
        selectedAudioOutputDeviceId?: string;
        className?: string;
        isPresentation?: boolean;
        status: StreamStatus;
        isFullHeight?: boolean;
        isFullWidth?: boolean;
        handleVideoClick?: () => void;
        onVideoPlaying?: () => void;
        onPictureInPictureChange?: (isPip: boolean) => void;
        onDoubleClick?: () => void;
        isContainerWide?: boolean;
        splashScreen?: {
            text: string;
            background: string;
        };
        videoElementId?: string;
        onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
        tabIndex?: number;
    }
>(
    (
        {
            mediaStream,
            selectedAudioOutputDeviceId,
            isPresentation = false,
            status,
            isFullHeight,
            isFullWidth,
            handleVideoClick,
            onVideoPlaying,
            onPictureInPictureChange,
            onDoubleClick,
            isContainerWide = false,
            splashScreen,
            videoElementId,
            tabIndex = -1,
            onKeyDown,
            ...props
        },
        ref,
    ) => {
        const {t} = useTranslation();
        const videoComponentRef = useRef<React.ElementRef<typeof Video>>(null);
        const [videoStatus, setVideoStatus] = useState<
            'loading' | 'playing' | 'failed'
        >('loading');
        const fullSizeWindowRef = useRef<HTMLDivElement>(null);

        useImperativeHandle(ref, () => ({
            focus: () => {
                /*
                 * The MeetingVideo component either renders Video or FullSizeWindow.
                 * When this imperative handle focus function executes
                 * we want to focus on whichever has been rendered.
                 */
                videoComponentRef.current?.focus();
                fullSizeWindowRef.current?.focus();
            },
        }));

        const isExpanded = status === StreamStatus.Expanded;
        const isExternal = status === StreamStatus.External;
        const isPip = status === StreamStatus.Pip;
        const wrapperTestId = getWrapperTestId(isPresentation, isPip);

        useEffect(() => {
            logger.debug(
                {mediaStream},
                'Media stream attached to Video component',
            );
        }, [mediaStream]);

        const handlePlaying = useCallback(() => {
            logger.debug('onPlaying callback called');
            setVideoStatus(() => {
                onVideoPlaying?.();
                return 'playing';
            });
        }, [onVideoPlaying]);
        const handleFailedToResume = useCallback(() => {
            setVideoStatus('failed');
        }, []);
        const handleResume = useCallback(() => {
            videoComponentRef.current?.resume();
        }, []);

        return (
            <VideoWrapper
                className={cx(styles.videoWrapper, {
                    [styles.centeredInWideContainer]: isContainerWide,
                    [styles.expanded]: isExpanded,
                })}
                isFullHeight={isFullHeight}
                isFullWidth={isFullWidth}
                borderRadius={isExternal ? 'none' : 'box'}
                onDoubleClick={onDoubleClick}
                background={isExternal ? 'black' : 'none'}
                flexDirection="row"
                data-testid={wrapperTestId}
                {...props}
            >
                {splashScreen && 'text' in splashScreen ? (
                    <FullSizeWindow
                        ref={fullSizeWindowRef}
                        tabIndex={tabIndex}
                        background="image"
                        backgroundImageSrc={splashScreen.background}
                        className={styles.splashScreen}
                        onClick={handleVideoClick}
                        onKeyDown={onKeyDown}
                    >
                        <CenterLayout centerText>
                            <Text
                                fontVariant={FontVariant.H2}
                                whiteSpace="preLine"
                                data-testid={TestId.VideoSplashText}
                            >
                                {splashScreen.text}
                            </Text>
                            <Audio
                                srcObject={mediaStream}
                                autoPlay
                                sinkId={selectedAudioOutputDeviceId}
                            />
                        </CenterLayout>
                    </FullSizeWindow>
                ) : (
                    <>
                        <Video
                            tabIndex={tabIndex}
                            id={videoElementId}
                            ref={videoComponentRef}
                            srcObject={mediaStream}
                            onPlaying={handlePlaying}
                            onFailedToResume={handleFailedToResume}
                            onPictureInPictureChange={onPictureInPictureChange}
                            onClick={handleVideoClick}
                            onKeyDown={onKeyDown}
                            disablePictureInPicture={isMobileDevice()}
                            sinkId={
                                isPresentation
                                    ? undefined
                                    : selectedAudioOutputDeviceId
                            }
                            muted={isPresentation}
                            data-testid={
                                isPresentation
                                    ? TestId.VideoPresentation
                                    : TestId.VideoMeeting
                            }
                            aria-label={
                                isPresentation
                                    ? t(
                                          'meeting.video-label-presentation',
                                          'Live video of another participant’s presentation',
                                      )
                                    : t(
                                          'meeting.video-label',
                                          'Live video of the meeting',
                                      )
                            }
                        />
                        {(!mediaStream || videoStatus === 'loading') && (
                            <Spinner
                                className={styles.overlay}
                                colorScheme="dark"
                                sizeModifier={isPip ? 'small' : 'medium'}
                            />
                        )}
                        {videoStatus === 'failed' && (
                            <Button
                                onClick={handleResume}
                                enhancerStart={
                                    <Icon source={IconTypes.IconPlayRound} />
                                }
                                className={styles.overlay}
                            >
                                {t(
                                    'meeting.error',
                                    'Click to resume the meeting video',
                                )}
                            </Button>
                        )}
                    </>
                )}
            </VideoWrapper>
        );
    },
);

MeetingVideo.displayName = 'MeetingVideo';

export type MeetingVideoProps = React.ComponentProps<typeof MeetingVideo>;
